.content[data-v-5da45f01] {
  font-size: 13px;
  margin: 20px;
}
.content .left-content[data-v-5da45f01] {
  width: 49%;
  margin-right: 1%;
  height: 500px;
  display: inline-block;
}
.content .right-content[data-v-5da45f01] {
  width: 50%;
  height: 500px;
  display: inline-block;
}
.content .left-content-item[data-v-5da45f01] {
  width: 100%;
  border: 1px solid #efefef;
}
.content .left-content-item .left-total[data-v-5da45f01] {
  width: 100%;
  background: #fff;
  height: 30px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .left-content-item .left-total span[data-v-5da45f01] {
  padding-right: 20px;
}
.content .left-content-item .left-total[data-v-5da45f01] .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #0a0a0a !important;
}
.content .left-content-item .left-total[data-v-5da45f01] .el-checkbox__inner {
  margin-top: 2px !important;
}
.content .left-content-item .left-title[data-v-5da45f01] {
  width: 100%;
  background: var(--themeColor, #17a2b8);
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  position: relative;
}
.content .left-content-item .subject[data-v-5da45f01] {
  background: #efefef;
}
.content .left-content-item .left-box[data-v-5da45f01] {
  border-right: 1px solid #efefef;
  line-height: 30px;
  height: 30px;
  border-top: 1px solid #efefef;
  text-align: center;
}
.content .left-content-item .left-box input[data-v-5da45f01] {
  background-color: aliceblue;
}
.content .left-content-item .leftOne[data-v-5da45f01] {
  border-right: 1px solid #efefef;
  line-height: 30px;
  height: 30px;
  border-top: 1px solid #efefef;
  padding: 0 10px;
}
.content .left-content-item .leftOne span[data-v-5da45f01] {
  padding-right: 25px;
}
.right-button[data-v-5da45f01] {
  position: fixed;
  top: 250px;
  right: 0;
  height: 120px;
  width: 40px;
  font-size: 14px;
  margin: 0 auto;
  line-height: 24px;
  background: var(--themeColor, #17a2b8);
  color: #fff;
  border-color: var(--themeColor, #17a2b8);
  border: 0;
  cursor: pointer;
}
.ccyc[data-v-5da45f01] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.left_sort_box[data-v-5da45f01] {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sort_style[data-v-5da45f01] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;
  margin-left: 5px;
}
.sort_style .sort-caret[data-v-5da45f01] {
  width: 0;
  height: 0;
  border: solid 5px transparent;
}
.sort_style .sort-caret.descending[data-v-5da45f01] {
  border-top-color: var(--themeColor, #17a2b8);
}
.sort_style .ascending[data-v-5da45f01] {
  border-bottom-color: var(--themeColor, #17a2b8);
}